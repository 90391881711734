import { clientsInstance } from "../axios";
export const newXLSXDownloads = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.post(`/promotion-results`, { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getPromotionResults = async ({ id, store, start, end }) => {
  let response = null;
  try {
    response = await clientsInstance.get(
      `/promotion-results/${id}/?store=${store}&start=${start}&end=${end}`
    );
  } catch (e) {}
  return response;
};

export const updatePromotionResults = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.put(`/promotion-results`, { ...values });
  } catch (e) {}
  return response;
};
