import axios from "axios";
import EventBus from "eventbusjs";
import Auth from "./auth";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const clientsInstance = axios.create({
  baseURL: process.env.REACT_APP_CLIENTS_URL,
});

clientsInstance.interceptors.request.use(async (request) => {
  try {
    const auth = new Auth();
    await auth.refreshToken();
    request.headers.Authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});

instance.interceptors.request.use(async (request) => {
  try {
    const auth = new Auth();
    await auth.refreshToken();
    request.headers.Authorization = `Bearer ${localStorage.id_token}`;
  } catch (err) {
    const error = new Error(err);
    return error.message;
  }
  return request;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      EventBus.dispatch("ALERT", this, "Unauthorized");
      EventBus.dispatch("LOGOUT", this);
    }
    return Promise.reject(error);
  }
);

export { clientsInstance };

export default instance;
