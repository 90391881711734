import history from "./history";
import auth0 from "auth0-js";
import EventBus from "eventbusjs";
import { AUTH_CONFIG } from "./auth0-variables";
import { notification } from "antd";
export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "token id_token",
    scope: "openid"
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft"
    });
  };

  login(email, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: "Username-Password-Authentication",
          email,
          password
        },
        (err, result) => {
          this.openNotificationWithIcon(
            "warning",
            "Incorrect email or password"
          );
          resolve({ err, result });
        }
      );
    });
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
        setTimeout(() => {
          EventBus.dispatch("GET_DATA", this);
        }, 1000);
      } else if (err) {
        history.replace("/login");
        setTimeout(() => {
          EventBus.dispatch("LOGIN_ERROR", this);
        }, 1000);
      }
    });
  }

  setSession(authResult, redirect = false) {
    console.log("setting session, values: ", authResult);
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(603800 * 1000 + new Date().getTime());
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    localStorage.setItem(
      "imageLocation",
      authResult.idTokenPayload["https://www.appinchina.co/picture"]
    );
    localStorage.setItem(
      "email",
      authResult.idTokenPayload["https://www.appinchina.co/email"]
    );
    localStorage.removeItem("e");
    localStorage.removeItem("p");
    // navigate to the home route
    if (redirect) {
      history.replace("/");
    }
  }

  logout() {
    localStorage.clear();
    history.replace("/login");
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt && window.localStorage.id_token;
  }

  refreshToken() {
    if (!this.isAuthenticated()) {
      return new Promise(resolve => {
        this.auth0.checkSession({}, (err, result) => {
          if (!err) {
            this.setSession(result);
          } else {
            console.log("ERROR while refreshing token", err);
          }
          resolve();
        });
      });
    }
  }
}
