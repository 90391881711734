import React, { useContext } from "react";
import { Menu } from "antd";
import { languageOptions } from "../../../languages";
import { LanguageContext } from "../../../context/languageContext";
const { Item } = Menu;

export default function LanguageSelector() {
  const { setLanguage, language } = useContext(LanguageContext);

  const handleLanguageChange = ({ key }) => {
    const selectedLanguage = languageOptions.find(item => item.id === key);
    setLanguage(selectedLanguage);
  };

  return (
    <Menu onSelect={handleLanguageChange} defaultSelectedKeys={[language.id]}>
      {languageOptions.map(item => (
        <Item key={item.id}>{item.text}</Item>
      ))}
    </Menu>
  );
}
