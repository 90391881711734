import React, { useState } from "react";
import { Modal, notification, Row, Button } from "antd";
import XLSX from "xlsx";
import ExcelReader from "../../UI/ExcelReader/ExcelReader";
import { newXLSXDownloads } from "../../../services/promotionResultsService";
import GetText from "../../UI/Text/Text";
export default ({ actualData, updateData, appId, visible, closeModal }) => {
  let [loading, setLoading] = useState(false);
  let [file, setFile] = useState(null);
  let makeRequest = async values => {
    let { data } = await newXLSXDownloads({ appId, downloads: values });
    if (data && data.message === "success") {
      setLoading(false);
      updateData([...values, ...actualData]);
      closeModal();
      showNotification();
    }
  };
  let readFile = data => {
    setLoading(true);
    makeRequest(data);
  };
  let showNotification = () => {
    notification.success({
      message: <GetText id="successMessage" />,
      description: <GetText id="uploadXlsxSuccess" />,
      placement: "bottomRight"
    });
  };

  let invalidFormatNotification = () => {
    notification.error({
      message: <GetText id="uploadXlsxError" />,
      placement: "bottomRight"
    });
  };

  const handleFile = () => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      // Parse data
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true
      });
      //Get first worksheet
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      //Convert array of arrays
      const format = XLSX.utils.sheet_to_json(ws);
      let formatJSON = JSON.stringify(format, null, 2);
      //transform data
      let arr = JSON.parse(formatJSON);
      let newHeaders = [
        "appname",
        "store",
        "date",
        "exposure",
        "downloads",
        "price_per_download",
        "download_rate",
        "amount"
      ];
      try {
        const transformData = arr.map(record => {
          const data = Object.values(record).reduce((prev, value, index) => {
            return {
              ...prev,
              [newHeaders[index]]:
                index === 2
                  ? new Date((value - (25567 + 1)) * 86400 * 1000).toISOString()
                  : value
            };
          }, {});
          return data;
        });

        readFile(transformData);
      } catch (error) {
        invalidFormatNotification();
      }
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <Modal
      title={<GetText id="uploadXlsxTitle" />}
      visible={visible}
      confirmLoading={loading}
      onCancel={closeModal}
      footer={null}
      destroyOnClose={true}
    >
      <ExcelReader loadingRequest={loading} setFile={setFile} />
      <Row type="flex" justify="center">
        <Button
          loading={loading}
          disabled={file ? false : true}
          type="primary"
          onClick={handleFile}
        >
          {loading ? (
            <GetText id="sendingInformation" />
          ) : (
            <GetText id="sendInformation" />
          )}
        </Button>
      </Row>
    </Modal>
  );
};
