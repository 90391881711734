import { clientsInstance } from "../axios";

export const getOptimization = async ({ id, store, start, end }) => {
  let response = null;
  try {
    response = await clientsInstance.get(
      `/promotion-optimization/${id}/?store=${store}&start=${start}&end=${end}`
    );
  } catch (e) {}
  return response;
};
export const updateOptimization = async (values) => {
  console.log(values);
  let response = null;

  try {
    response = await clientsInstance.put(`/promotion-optimization`, {
      ...values,
    });
  } catch (e) {}
  return response;
};
