const ch = {
  promotionResultsTitle: "Promotion Results",
  optimizationInformationTitle: "Optimization Information",
  attachmentsTitle: "Attachments",
  saveChangesButton: "Save Changes",
  uploadXlsx: "Upload XLSX",
  uploadXlsxTitle: "Upload XLSX file",
  uploadXlsxError: "XLSX file invalid format",
  uploadXlsxSuccess: "Table values updated from file",
  dataPerDayTitle: "Data Per Day",
  saving: "Saving...",
  promotionResultsTableHeaders: {
    date: "Date",
    exposure: "Exposure",
    downloads: "Downloads",
    amountSpent: "Amount Spent (¥)",
    price: "Price (¥)",
    downloadRate: "Download Rate (%)"
  },
  optimizationInformationTableHeaders: {
    date: "Date",
    optimizationSummary: "Optimization Summary",
    optimizationPlan: "Optimization Plan"
  },
  uploadButton: "Upload",
  settingsMenuItem: "Settings",
  logOutMenuItem: "Log Out",
  profileTitle: "Profile",
  firstNameTitle: "First Name",
  lastNameTitle: "Last Name",
  emailTitle: "Email",
  profileImageTitle: "Profile Image",
  changePasswordButton: "Change Password",
  saveButton: "Save",
  successMessage: "Success",
  successUpdateMessage: "The information has been updated in the database.",
  updateConfirmationMessage:
    "Are you sure you want to save all the information?",
  dateOutOfRangeError: "Range should not be greater than 60 days",
  deleteAttachmentConfirmationMessage:
    "Are you sure you want to delete this attachment?",
  sendingInformation: "Sending Information...",
  sendInformation: "Send",
  informationUpdatedSuccess: "Information updated!"
};

export default ch;
