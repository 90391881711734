import React, { useState, useEffect } from "react";
import moment from "moment";
import classes from "./PromotionResults.module.less";
import {
  Table,
  Input,
  DatePicker,
  Button,
  Popconfirm,
  notification,
  Select,
  Card
} from "antd";
import { getDefaultDate } from "../../functions";
import {
  getPromotionResults,
  updatePromotionResults
} from "../../services/promotionResultsService";
import GetText from "../UI/Text/Text";
import { getAllStores } from "../../services/storesService";
import XLSXModal from "./XLSXModal/XLSXModal";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default ({ appId, app }) => {
  let [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [store, setStore] = useState(null);
  let [changedData, setChangedData] = useState(false);
  let [dateRange, setDateRange] = useState(getDefaultDate());
  let [modalOpen, setModalOpen] = useState(false);
  let [storesOptions, setStoresOptions] = useState([]);
  let validationDate = () => {
    return notification.info({
      message: <GetText id="dateOutOfRangeError" />
    });
  };
  let validateRange = m => {
    let diff = m[1].diff(m[0], "days");

    if (diff <= 60) {
      setDateRange(m);
    } else {
      validationDate();
    }
  };

  let updateValue = (value, column, rowIndex) => {
    let dataCopy = [...data];
    dataCopy[rowIndex][column] = value;
    setData(dataCopy);
    setChangedData(true);
  };

  let saveData = async () => {
    let { data: response } = await updatePromotionResults({ appId, data });
    if (response && response.message === "success") {
      notification.success({
        message: <GetText id="successMessage" />,
        description: <GetText id="successUpdateMessage" />,
        placement: "bottomRight"
      });
    }
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      className: classes.date,
      width: "110px",
      render: date => moment(date).format("YYYY-MM-DD")
    },
    {
      title: "Exposure",
      dataIndex: "exposure",
      key: "exposure",
      render: (e, _, index) => (
        <Input
          type="number"
          value={e}
          onChange={e => updateValue(e.target.value, "exposure", index)}
        />
      ),
      align: "right"
    },
    {
      title: "Downloads",
      dataIndex: "downloads",
      key: "downloads",
      render: (e, _, index) => (
        <Input
          type="number"
          value={e}
          onChange={e => updateValue(e.target.value, "downloads", index)}
        />
      ),
      align: "right"
    },
    {
      title: "Amount Spent (¥)",
      dataIndex: "amount",
      key: "amount",
      render: (e, _, index) => (
        <Input
          type="number"
          value={e}
          onChange={e => updateValue(e.target.value, "amount", index)}
        />
      ),
      align: "right"
    },
    {
      title: "Price (¥)",
      dataIndex: "price_per_download",
      key: "price_per_download",
      render: (e, _, index) => (
        <Input
          type="number"
          min={0}
          value={e}
          onChange={e =>
            updateValue(e.target.value, "price_per_download", index)
          }
        />
      ),
      align: "right"
    },
    {
      title: "Download Rate (%)",
      dataIndex: "download_rate",
      key: "download_rate",
      render: (e, _, index) => (
        <Input
          type="number"
          value={e}
          onChange={e => updateValue(e.target.value, "download_rate", index)}
        />
      ),
      align: "right"
    }
  ];
  let makeRequest = async () => {
    setLoading(true);
    let { data: response } = await getPromotionResults({
      id: appId,
      store,
      start: dateRange[0].format("YYYY-MM-DD"),
      end: dateRange[1].format("YYYY-MM-DD")
    });
    setLoading(false);
    if (response && response.expenses) {
      setData(response.expenses);
    }
  };

  const getStoresValues = async () => {
    let { data: allStores } = await getAllStores();
    if (data) {
      let storesApps = app.stores.split(",");
      let storesSelected = storesApps.map(id => {
        return allStores.filter(e => e.id === id)[0].fullname;
      });
      setStoresOptions(storesSelected);
      setStore(storesSelected[0]);
    }
  };

  useEffect(() => {
    getStoresValues();
    makeRequest();
  }, []);

  useEffect(() => {
    makeRequest();
  }, [store, dateRange]);
  useEffect(() => {
    makeRequest();
    getStoresValues();
  }, [appId]);
  return (
    <div className={classes.container}>
      <div className={classes.controls}>
        <RangePicker
          loading={loading}
          disabled={loading}
          value={[dateRange[0], dateRange[1]]}
          onChange={moment => {
            validateRange(moment);
          }}
          format="YYYY-MM-DD"
        />
        <Select
          loading={loading}
          disabled={loading}
          value={store}
          style={{ width: 120 }}
          onChange={newStore => setStore(newStore)}
        >
          {storesOptions.map(it => (
            <Option value={it}>{it}</Option>
          ))}
        </Select>
        <Popconfirm
          placement="bottom"
          title={<GetText id="updateConfirmationMessage" />}
          onConfirm={saveData}
          okText="Yes"
          cancelText="No"
        >
          <Button icon="save" type="primary" disabled={!changedData || loading}>
            <GetText id="saveChangesButton" />
          </Button>
        </Popconfirm>
      </div>
      <Card
        title={<GetText id="dataPerDayTitle" />}
        extra={
          <Button
            disabled={loading}
            icon="cloud-upload"
            onClick={() => setModalOpen(true)}
          >
            <GetText id="uploadXlsx" />
          </Button>
        }
      >
        <Table
          loading={loading}
          dataSource={data}
          columns={columns}
          size="small"
        />
      </Card>
      <XLSXModal
        visible={modalOpen}
        closeModal={() => setModalOpen(false)}
        actualData={data}
        updateData={setData}
        appId={appId}
      />
    </div>
  );
};
