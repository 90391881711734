import React, { useEffect } from "react";
import { notification } from "antd";
import history from "../../../history";

export default () => {
  let showNotification = () => {
    return notification.info({
      message: "You don't have access to any app right now",
      duration: 5,
    });
  };
  const logOut = () => {
    let i = localStorage.length;
    while (i--) {
      let key = localStorage.key(i);
      if (!/_newFeature/.test(key)) {
        localStorage.removeItem(key);
      }
    }

    history.replace("/login");
  };
  useEffect(() => {
    showNotification();
    logOut();
  }, []);

  return <div></div>;
};
