import React from "react";
import classes from "./ConfirmationDialog.module.css";
import Dialog from "../../UI/Dialog/Dialog";
import Button from "../../UI/Button/Button";

const confirmationDialog = (props) => {
  return (
    <Dialog display={props.display} close={props.close} width="300px">
      <h2 className={classes.Title}>{props.title}</h2>
      <div className={classes.ButtonContainer}>
        <Button click={props.accept} type="Ghost">
          YES
        </Button>
        <Button click={props.close} type="Ghost">
          NO
        </Button>
      </div>
    </Dialog>
  );
};

export default confirmationDialog;
