import en from "./en";
import ch from "./ch";

export const dictionaryList = {
  en,
  ch
};

export const languageOptions = [
  { id: "en", text: "English" },
  { id: "ch", text: "Chinese" }
];
