import React, { Fragment } from 'react';
import classes from './LoginForm.module.css';
import FullBackground from '../../UI/FullBackground/FullBackground';
import { companyLogo } from '../../../images.js';

export default (props) => (
	<Fragment>
		<FullBackground />
		<div className={classes.Container}>
			<div className={classes.Form}>
				<img src={companyLogo} alt="Company Logo" />
				<div>
					{props.children}
				</div>
			</div>
		</div>
	</Fragment>
)

