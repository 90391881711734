import React, { useState, useEffect } from "react";
import history from "../../../history";
import classes from "./AppSelect.module.less";
import { Select, Divider, Icon } from "antd";
import defaultImage from "../../../assets/default-app.svg";

function verifyAppId(id, apps, section) {
  let appFound = apps.find((app) => app.id === id);
  if (!appFound) {
    history.push(`/`);
  }
}
const AppSelect = (props) => {
  const { Option, OptGroup } = Select;
  let [hideDemo, setHideDemo] = useState(false);
  verifyAppId(props.appId, props.apps, props.section, setHideDemo);
  const handleChange = (value) => {
    history.push(history.location.pathname.replace(props.appId, value));
  };
  let apps = props.apps.filter(
    ({ id }) => id !== "1234-8475-9439-9494" && id !== "1234-8475-9439-9495"
  );
  let demoApps = props.apps.filter(
    ({ id }) => id === "1234-8475-9439-9494" || id === "1234-8475-9439-9495"
  );
  useEffect(() => {
    handleChange(props.appId);
  }, [props.appId]);
  return (
    <Select
      className={classes.select}
      placeholder="Select an App..."
      value={props.appId ? props.appId : "New App..."}
      onChange={handleChange}
      dropdownRender={(menu) => <div>{menu}</div>}
    >
      <OptGroup label="Apps">
        {apps
          ? apps.map((it) => {
              let imageData = it.image_data ? it.image_data : defaultImage;
              imageData = /uploads|static|googleusercontent/.test(imageData)
                ? imageData
                : `data:image/png;base64,${imageData}`;
              return (
                <Option key={it.id} value={it.id} label={it.name}>
                  <span role="img" aria-label={it.name}>
                    <img
                      src={imageData}
                      alt="App Logo"
                      className={classes.SelectImg}
                    />{" "}
                    {it.name}
                  </span>
                </Option>
              );
            })
          : null}
      </OptGroup>
    </Select>
  );
};

export default AppSelect;
