import React, { Component } from "react";
import classes from "./ForgotPasswordDialog.module.css";
import Input from "../../UI/Input/Input";
import functions from "../../../functions";
import { Modal, notification } from "antd";
import { recoverPassword } from "../../../services/acccountService";
class ForgotPasswordDialog extends Component {
  state = {
    email: {
      value: "",
      validation: {
        required: true,
        isEmail: true
      },
      valid: false,
      validated: false
    },
    loading: false
  };
  openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      placement: "bottomLeft"
    });
  };

  handleInputChange = (event, name) => {
    let state = { ...this.state };
    state[name] = {
      ...state[name],
      validated: false,
      value: event.target.value
    };
    this.setState(state);
  };

  submitForm = event => {
    event.preventDefault();
    let state = { ...this.state };
    let element = {
      ...state.email,
      valid: functions.checkValidity(state.email.value, state.email.validation),
      validated: true
    };
    this.setState({ email: element });
    if (element.valid) {
      this.sendEmail();
    }
  };

  sendEmail = async event => {
    let data = {
      method: "POST",
      email: this.state.email.value
    };
    this.setState({ loading: true });

    let response = await recoverPassword(data);
    if (response.data && response.data.message === "success") {
      this.openNotificationWithIcon(
        "info",
        "We've just sent you an email to reset your password."
      );
      this.props.close();
    } else {
      this.openNotificationWithIcon("warning", "An error happened");
      this.props.close();
    }
  };

  render() {
    let { handleInputChange } = this;
    let { email } = this.state;
    return (
      <Modal
        title="Forgot your password?"
        visible={this.props.display}
        onOk={this.submitForm}
        confirmLoading={this.state.loading}
        onCancel={this.props.close}
      >
        <p className={classes.Description}>
          Enter your email address and we'll help you restore it
        </p>
        <Input
          {...email}
          placeholder="Email"
          onChange={event => handleInputChange(event, "email")}
          errorMessage="Valid email required"
        />
      </Modal>
    );
  }
}

export default ForgotPasswordDialog;
