import { clientsInstance } from "../axios";

export const changePassword = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.put("/password", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const recoverPassword = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.post("/forgotpassword", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const registerEmail = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.post("/register", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
export const updateInfo = async (values) => {
  let response = null;
  try {
    response = await clientsInstance.put("/profile", { ...values });
  } catch (e) {
    console.log("error", e);
  }
  return response;
};

export const getUserInfo = async () => {
  let response = null;
  try {
    response = await clientsInstance.get("/profile");
  } catch (e) {
    console.log("error", e);
  }
  return response;
};
