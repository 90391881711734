import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import history from "../../history";
import AccountSettings from "./AccountSettings/AccountSettings";
import classes from "./Settings.module.less";
import GetText from "../UI/Text/Text";
const { TabPane } = Tabs;

const Settings = ({ ...props }) => {
  let [tab, setTab] = useState(0);

  useEffect(() => {
    let { section } = props;
    setTab(section);
  }, []);

  const updateTab = section => {
    setTab(section);
    history.push(`/settings/${section}/${props.appId}`);
  };
  return (
    <Tabs
      activeKey={`${tab}`}
      onChange={e => updateTab(e)}
      className={classes.settings}
    >
      <TabPane tab={<GetText id="profileTitle" />} key="profile">
        <AccountSettings />
      </TabPane>
    </Tabs>
  );
};

export default Settings;
