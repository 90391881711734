import React, { useState, useEffect } from "react";
import history from "../../history";
import classes from "./AntMenu.module.less";
import { companyLogoWhite, logoGreen } from "../../images";
import { Menu, Icon, Drawer } from "antd";
import GetText from "../UI/Text/Text";

const AntMenu = ({ menuOption, section, ...props }) => {
  const { SubMenu } = Menu;
  const [openKeys, setOpenKeys] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const menuData = [
    { title: "promotionResultsTitle", key: "promotion-results", icon: "like" },
    {
      title: "optimizationInformationTitle",
      key: "optimization-information",
      icon: "schedule",
    },
    { title: "attachmentsTitle", key: "attachments", icon: "file-image" },
  ];

  const handleChange = (e) => {
    let { keyPath } = e;
    let { appId } = props;
    redirectToPage(keyPath, appId);
  };

  const redirectToPage = (keyPath, appId) => {
    setDrawerOpen(false);
    history.push({
      pathname: `/${keyPath[0]}/${appId}`,
    });
  };

  const onOpenChange = (newOpenKeys) => {
    openKeys.forEach((key) => {
      let indexToRemove = newOpenKeys.findIndex((newKey) => {
        return newKey === key;
      });
      if (indexToRemove >= 0) {
        newOpenKeys.splice(indexToRemove, 1);
      }
    });
    setOpenKeys(newOpenKeys);
  };

  useEffect(() => {
    setOpenKeys(menuOption ? [menuOption] : []);
  }, []);

  let MenuContent = () => (
    <Menu
      mode="inline"
      onClick={handleChange}
      selectedKeys={[selectedKeys]}
      onOpenChange={onOpenChange}
      openKeys={openKeys}
    >
      {menuData.map((it) =>
        it.submenus && !it.visible ? (
          <SubMenu
            key={it.key}
            title={
              <span>
                <Icon type={it.icon} />
                <span>
                  <GetText id={it.title} />
                </span>
              </span>
            }
          >
            {it.submenus.map((subOption) =>
              !subOption.visible ? (
                <Menu.Item key={`${it.key}#${subOption.key}`}>
                  {subOption.title}
                </Menu.Item>
              ) : null
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={it.key}>
            <Icon type={it.icon} />
            <span>
              <GetText id={it.title} />
            </span>
          </Menu.Item>
        )
      )}
    </Menu>
  );

  let selectedKeys =
    menuOption !== section ? `${menuOption}#${section}` : section;

  return (
    <div className={classes.container}>
      <div className={classes.imgContainer}>
        <img
          className={classes.Logo}
          src={companyLogoWhite}
          alt="Company Logo"
        />
        <img
          className={classes.AltLogo}
          src={companyLogoWhite}
          alt="Company Logo"
        />
        <Icon
          type="menu"
          onClick={() => setDrawerOpen(true)}
          className={classes.menuIcon}
        />
      </div>
      <div className={classes.sideMenu}>
        <MenuContent />
      </div>
      <Drawer
        placement="left"
        closable={true}
        title={<img src={logoGreen} className={classes.drawerLogo} />}
        onClose={() => setDrawerOpen(false)}
        visible={drawerOpen}
      >
        <div className={classes.drawerMenu}>
          <MenuContent />
        </div>
      </Drawer>
    </div>
  );
};

export default AntMenu;
