import EventBus from "eventbusjs";
import Axios from "./axios";
import axios from "axios";
import { formatCountdown } from "antd/lib/statistic/utils";
import moment from "moment";
const functions = {
  capitalize: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
  checkValidity: ({ value, validation, width, height }) => {
    let isValid = true;
    if (!validation) {
      return true;
    }
    if (validation.required) {
      isValid = !!value && value.toString().trim() && isValid;
    }
    if (validation.fileRequired) {
      isValid = !!value && isValid;
    }
    if (validation.minLength) {
      isValid = !!value && value.length >= validation.minLength && isValid;
    }
    if (validation.maxLength) {
      isValid = !!value && value.length <= validation.maxLength && isValid;
    }
    if (validation.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = !!value && pattern.test(value) && isValid;
    }
    if (validation.password) {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})/;
      isValid = !!value && pattern.test(value) && isValid;
    }
    if (validation.isNumeric) {
      const pattern = /^\d+$/;
      isValid = !!value && pattern.test(value) && isValid;
    }
    if (validation.matches) {
      isValid = isValid && value === validation.matches;
    }
    if (validation.resolution) {
      if (
        +width == +validation.resolution.width &&
        +height == +validation.resolution.height
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    }
    return isValid;
  },
  dataURLtoFile: (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  treatAsUTC: (date) => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  },
  daysBetween: (startDate, endDate) => {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (
      (functions.treatAsUTC(endDate) - functions.treatAsUTC(startDate)) /
      millisecondsPerDay
    );
  },
  styleAsNumber: (str) => {
    return str !== undefined && str !== null
      ? (str + "").replace(/\b(\d+)((\.\d+)*)\b/g, (a, b, c) => {
          return (
            (b.charAt(0) > 0 && !(c || ".").lastIndexOf(".")
              ? b.replace(/(\d)(?=(\d{3})+$)/g, "$1,")
              : b) + c
          );
        })
      : "";
  },
  styleAsTime: (time) => {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  },
  splitAndCapitalize: (string) => {
    return string
      ? string
          .toLowerCase()
          .split("_")
          .reduce((arr, s) => {
            arr.push(s.charAt(0).toUpperCase() + s.slice(1));
            return arr;
          }, [])
          .join(" ")
      : "";
  },
  reverseSplitAndCapitalize: (string) => {
    return string
      ? string
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]/g, "_")
          .replace(/\s/g, "_")
      : "";
  },
  makeDateReadable: (date) => {
    let d = new Date(Date.parse(date, "YYY-MM-DD")).toDateString();
    return d.substr(d.indexOf(" ")).trim();
  },
  startIntercom: () => {
    window.Intercom("boot", {
      app_id: "f751hqif",
      email: window.localStorage.user,
      name: window.localStorage.name,
      user_hash: window.localStorage.user_hash,
    });
  },
  createIframe: (src) => {
    var i = document.createElement("iframe");
    i.style.display = "none";
    i.onload = () => {
      i.parentNode.removeChild(i);
    };
    i.src = src;
    document.body.appendChild(i);
  },
  handleFormInputChange: (event, inputName, component) => {
    if (event.target.files) {
      if (event.target.files && event.target.files.length) {
        let file = event.target.files[0];
        if (component.state.form[inputName].validation.resolution) {
          functions.getDimensions(file, inputName, component);
        }
        functions.submitFile(file, file.name, inputName, component);
      } else {
        console.log("cancelling file submission...");
        functions.saveInStateForm(component, inputName, "");
      }
    } else {
      functions.saveInStateForm(component, inputName, event.target.value);
    }
  },
  saveInStateForm: (component, targetName, value = null) => {
    console.log("saving state form", targetName, value);
    let form = {
      ...component.state.form,
      [targetName]: {
        ...component.state.form[targetName],
        validated: false,
        value,
      },
    };
    component.setState({
      form: form,
    });

    clearTimeout(component.timeout);
    component.timeout = setTimeout(() => {
      functions.makeRequest(component, true);
    }, 500);
  },

  makeRequest: (component, isDraft = false) => {
    console.log("request");
    if (component.state.savingFile && !isDraft) {
      EventBus.dispatch(
        "ALERT",
        component,
        "Please wait until all files are stored"
      );
      return;
    } else if (!isDraft) {
      EventBus.dispatch(
        "ALERT",
        component,
        "Submitting information, do not close this page"
      );
    } else {
      component.setState((prevState) => ({
        savingFile: prevState.savingFile + 1,
      }));
    }
    component.setState({ loading: true });
    const form = { ...component.state.form };
    const form_data = {};
    for (var key in form) {
      form_data[key] = form[key].value;
    }
    Axios.post(component.formType, {
      appId: component.props.app.id,
      isDraft: isDraft,
      form_data,
    })
      .then((response) => {
        component.setState({ loading: false });
        if (response.data.message === "success") {
          setTimeout(
            () =>
              component.setState((prevState) => ({
                savingFile:
                  prevState.savingFile > 0 ? prevState.savingFile - 1 : 0,
              })),
            1000
          );
          setTimeout(
            () => console.log("savingfile", component.state.savingFile),
            1000
          );
          //special case for app forms to insert/update elements
          if (component.formType === "app-form" && !isDraft) {
            let submissions = component.state.submissions;
            let submissionIndex = submissions.findIndex(
              (it) => it.version === response.data.app_form.version
            );
            if (submissionIndex !== -1) {
              submissions[submissionIndex] = response.data.app_form;
            } else {
              submissions.push(response.data.app_form);
            }
            component.setState({ submissions });
          }
          if (!isDraft)
            EventBus.dispatch("ALERT", component, "Information submitted!");
        } else {
          setTimeout(
            () =>
              component.setState((prevState) => ({
                savingFile: prevState.savingFile - 1,
              })),
            1000
          );
          throw response;
        }
      })
      .catch((error) => {
        component.setState({ loading: false });
        EventBus.dispatch("ALERT", component, "An error occurred");
      });
  },
  getDimensions: (file, inputName, component, url, validated = true) => {
    console.log("validated", validated);
    if (file || url) {
      var img = new Image();
      img.src = url || window.URL.createObjectURL(file);
      img.onload = () => {
        let form = JSON.parse(JSON.stringify(component.state.form));
        form[inputName] = {
          ...form[inputName],
          width: img.naturalWidth,
          height: img.naturalHeight,
          validated,
        };
        form[inputName].valid = functions.checkValidity(form[inputName]);
        component.setState({ form });

        window.URL.revokeObjectURL(img.src);
      };
    } else {
      //No file was input or browser doesn't support client side reading
      EventBus.dispatch("ALERT", component, "Error while uploading file");
    }
  },
  submitFile: (file, fileName, inputName, component) => {
    console.log("filename2", fileName, "inputname2", inputName);
    let timestamp = new Date().getTime();
    var formData = new FormData();
    formData.set("uploads[]", file);
    formData.set("name", fileName);
    formData.set("timestamp", timestamp);
    component.setState((prevState) => ({
      savingFile: prevState.savingFile + 1,
    }));
    axios
      .post("https://appinchina.space/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("progress", percentCompleted);
          component.setState(({ form }) => ({
            form: {
              ...form,
              [inputName]: { ...form[inputName], progress: percentCompleted },
            },
          }));
        },
      })
      .then((response) => {
        component.setState(({ form }) => ({
          form: {
            ...form,
            [inputName]: { ...form[inputName], progress: null },
          },
        }));
        if (response.data === "success") {
          setTimeout(
            () =>
              component.setState((prevState) => ({
                savingFile: prevState.savingFile - 1,
              })),
            1000
          );
          functions.saveInStateForm(
            component,
            inputName,
            timestamp + "/" + fileName
          );
          setTimeout(() => console.log("form", component.state.form), 1000);
        } else throw response;
      })
      .catch((error) => {
        EventBus.dispatch("ALERT", component, "An error occurred");
        setTimeout(
          () =>
            component.setState((prevState) => ({
              savingFile: prevState.savingFile - 1,
            })),
          1000
        );
      });
  },
  restoreData: (component) => {
    return Axios.get(`/${component.formType}/${component.props.app.id}`);
  },
  setStateFromDraft: (data, component) => {
    let form = { ...component.state.form };
    Object.keys(data).forEach((key) => {
      if (key in component.state.form) {
        form[key] = { ...form[key], value: data[key] };
      }
    });
    component.setState({ form: form });
  },
  submitClientForm: (event, component) => {
    event.preventDefault();
    let form = { ...component.state.form };
    let formValid = true;
    for (var key in form) {
      let element = {
        ...form[key],
        valid: functions.checkValidity(form[key]),
        validated: true,
      };
      if (!element.valid) console.log("invalid: ", key);
      form[key] = element;
      formValid = element.valid && formValid;
    }
    component.setState({
      form: form,
    });
    if (formValid) functions.makeRequest(component, false);
    else {
      EventBus.dispatch("ALERT", component, "Please fill all the fields");
      component.confirmSubmission();
    }
  },
  submitClientFormIncomplete: (component) => {
    functions.makeRequest(component, false);
  },
};

export const sortByDateDesc = (a, b) => {
  return new Date(b.date).getTime() - new Date(a.date).getTime();
};

export const toUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});
export const USDToInt = new Intl.NumberFormat().format(1111).replace(/1/g, "");
export const downloadCSV = (data, filename) => {
  console.log("downloading csv, data:", data);
  let csvContent =
    "data:text/csv;charset=utf-8," + data.map((e) => e.join(",")).join("\n");
  let encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export default functions;

export const getDistributionTitle = (section) => {
  switch (section) {
    case "company-form":
      return "Business Information";
    case "app-information":
      return "App Information";
    case "copyright-certificate":
      return "Copyright Certificate";
    case "security-assesment":
      return "Security Assessment";
    default:
      return "App Information";
  }
};

export const getLocalizationTitle = (section) => {
  switch (section) {
    case "integrations":
      return "Integrations";
    case "hosting":
      return "Hosting";
    case "intellectual-property":
      return "Intellectual Propery";
    case "compliance":
      return "Compliance";
    case "translation":
      return "Translation";
    default:
      return "Localization";
  }
};
export const getMonetizationTitle = (section) => {
  switch (section) {
    case "summary":
      return "Summary";
    case "transactions":
      return "Transactions";
    case "users":
      return "Users";
    case "refunds":
      return "Refunds";
    case "statement-reports":
      return "Statement Reports";
    case "account-details":
      return "Account Details";
    case "transfer-history":
      return "Transfer History";
    default:
      return "Monetization";
  }
};
export const getAnalyticsTitle = (section) => {
  switch (section) {
    case "general-information":
      return "General Information";
    case "users":
      return "Users";
    case "payments":
      return "Payments";
    default:
      return "Analytics";
  }
};

export const calculateBytesSize1 = (bytes) => {
  let prefixes = ["B", "KB", "MB", "GB"];
  let res = bytes;
  let acc = 0;
  while (res > 1024 || acc < 3) {
    res = res / 1024;
    acc += 1;
  }
  return `${functions.styleAsNumber(res % 1 == 0 ? res : res.toFixed(2))} ${
    prefixes[acc]
  }`;
};

export const getDefaultDate = () => {
  let end = moment();
  let start = moment().subtract(29, "days");
  return [start, end];
};
