import { clientsInstance } from "../axios";

export const getStoresAttachments = async (appId, stores) => {
  let response = null;

  try {
    response = await clientsInstance.get(
      `/promotion-attachments?appId=${appId}&stores=${stores}`
    );
  } catch (e) {}
  return response;
};

export const updateStoresAttachments = async (values) => {
  let response = null;

  try {
    response = await clientsInstance.post(`/promotion-attachments`, {
      ...values,
    });
  } catch (e) {}
  return response;
};
